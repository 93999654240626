'use client';

import React from 'react';

import { Logo } from '@/assets/logo';

import { Navbar, NavbarActions, NavbarBrand, NavbarLogo } from '@/components/navbar/navbar';
import { Button } from '@av/ui/button';
import { Phone } from 'lucide-react';
import ShoppingBag from './shopping-bag';
import { UpperNavigationMenu } from './upper-navigation-menu';
import MobileMenu from './mobile-menu';
import { NavigationItem } from '@av/ui/navigation-menu';
import TaxStateDropdown from './tax-state-dropdown';
import Search from './search';
import { CategoriesNavigationMenu } from './categories-navigation-menu';
import ReviewRating from './review-rating';
import { usePathname } from 'next/navigation';
import Banner from './banner';
import UserNav from './user-nav';
import OrderListsMenuItem from './order-lists-menu-item';

export type HeaderType = {
  rating: number;
  underMenuItems: NavigationItem[];
  upperMenuItems: NavigationItem[];
  banner?: string;
};

const LayoutHeader = ({ header, client }: { header: HeaderType; client: boolean }) => {
  const pathname = usePathname();

  return (
    <header className="fixed inset-x-0 top-0 z-[999] bg-white">
      <Navbar data-testid="topbar" className="hidden h-14 py-3 sm:block">
        <ReviewRating rating={header.rating} />
        <UpperNavigationMenu items={header.upperMenuItems} />
        {/* TODO implement in new ticket */}
        {/* <NavbarDropdown>
                <NavbarDropdownTrigger className="text-sm text-description">
                  Nederlands
                </NavbarDropdownTrigger>
                <NavbarDropdownContent>
                  <NavbarDropdownMenuGroup>
                    <NavbarDropdownLink href="#">Nederlands</NavbarDropdownLink>
                  </NavbarDropdownMenuGroup>
                </NavbarDropdownContent>
              </NavbarDropdown> */}
        <TaxStateDropdown />
      </Navbar>
      <Navbar data-testid="navbar" className="h-20">
        <NavbarBrand className="grow-0 sm:mr-2">
          <NavbarLogo href="/" aria-label="Accuraat Verhuur">
            <Logo className="h-12 w-auto py-2 sm:h-16" />
          </NavbarLogo>
        </NavbarBrand>
        <CategoriesNavigationMenu />
        {!pathname.includes('/betalen/') && <Search />}
        <NavbarActions className="ml-auto hidden items-center gap-1 sm:flex">
          <UserNav />
          <Button variant="secondary" size="icon" href="tel:0206410920" aria-label="call us">
            <Phone className="size-5" />
            <span className="sr-only">Bel ons</span>
          </Button>
          <ShoppingBag />
          <OrderListsMenuItem />

          {/* TODO implement feature in new ticket */}
          {/* <Button variant="secondary" size="icon">
                <User className="size-6" />
                <span className="sr-only">Inloggen</span>
              </Button> */}
          {/* TODO implement feature in new ticket */}
          {/* <Button variant="secondary" size="icon">
                <Heart className="size-6" />
                <span className="sr-only">Gewenst</span>
                <Badge className="absolute -top-1 right-0" size="icon">
                  3
                </Badge>
              </Button> */}
          {/* TODO implement feature in new ticket */}
          {/* <Button variant="secondary" size="icon">
                <ShoppingBag className="size-6" />
                <span className="sr-only">Winkelwagen</span>
                <Badge className="absolute -top-1 right-0" size="icon">
                  3
                </Badge>
              </Button> */}
        </NavbarActions>
        <div className="sm:hidden">
          <ShoppingBag />
        </div>
        <div className="sm:hidden">
          <OrderListsMenuItem />
        </div>
        <MobileMenu items={header.upperMenuItems} />
      </Navbar>
      {!!header.banner && <Banner content={header.banner} client={client} />}
    </header>
  );
};

export default LayoutHeader;
